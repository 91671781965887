import { ENVIRONMENT_SET } from '../constants/broadcast';
import { broadcast } from '../services/broadcastService';
import { ServicesConfig } from '../typings/ServicesConfig';

export const BETA_API_HOST = 'https://beta-api.ambita.com';
export const PROD_API_HOST = 'https://api.ambita.com';

export const BETA_INTERNAL_HOST = 'https://beta-internal.ambita.com';
export const PROD_INTERNAL_HOST = 'https://internal.ambita.com';

export const ENVIRONMENT_BETA = 'beta';
export const ENVIRONMENT_PROD = 'prod';

export const CLIENT_ID_INFOLAND = 'NYEINFOLAND';
export const RESTRICTED_CLIENT_ID_INFOLAND = 'NYEINFOLAND_RESTRICTED';

// Possible grant_type's = 'client_credentials', 'password', 'refresh_token'
export const GRANT_TYPE = 'client_credentials';
export const SALES_CLIENT = 'NYEINFOLAND';

export const EDR_ONLINE_ADDRESS = '/edronline-2.2/';
export const AUTHENTICATION_API_PATH = '/authentication/v2';
export const AUTHENTICATION_TOKEN_PATH = `${AUTHENTICATION_API_PATH}/token`;
export const REALTY_API_PATH = '/realty/v1';
export const REGISTER_API_PATH = '/register/v1';
export const EDR_PRODUCT_API_PATH = '/edrproduct/v1';
export const INVOICE_API_PATH = '/invoice/v1';
export const PRODUCT_CATALOG_API_PATH = '/productcatalog/v1';
export const SHOPPING_API_PATH = '/shopping/v2';
export const SHOPPING_API_PATH_V3 = '/shopping/v3';
export const PRICE_CALCULATOR_API_PATH = '/pricecalculator/v2';
export const SUPPLIER_API_PATH = '/supplier/v1';

let config: ServicesConfig.Config;

const generateConfig = (environment: string): ServicesConfig.Config => {
  const apiHost = environment === ENVIRONMENT_PROD ? PROD_API_HOST : BETA_API_HOST;
  const internalHost = environment === ENVIRONMENT_PROD ? PROD_INTERNAL_HOST : BETA_INTERNAL_HOST;

  return {
    API_HOST: apiHost,
    AUTHENTICATION_ADDRESS: `${apiHost}${AUTHENTICATION_API_PATH}`,
    AUTHENTICATION_DEPARTMENT: `${apiHost}${AUTHENTICATION_API_PATH}/departments`,
    AUTHENTICATION_ORGANISATIONS: `${apiHost}${AUTHENTICATION_API_PATH}/organizations`,
    AUTHENTICATION_PASSWORD: `${apiHost}${AUTHENTICATION_API_PATH}/passwords`,
    AUTHENTICATION_SUPPLIER_MESSAGES: `${apiHost}${AUTHENTICATION_API_PATH}/suppliermessages`,
    AUTHENTICATION_SUPPLIER_SETTINGS: `${apiHost}${AUTHENTICATION_API_PATH}/suppliersettings`,
    AUTHENTICATION_TOKEN_PATH,
    AUTHENTICATION_USERS: `${apiHost}${AUTHENTICATION_API_PATH}/users`,
    CLIENT_ID: CLIENT_ID_INFOLAND,
    COUNTY_INFO_ADDRESS: `${apiHost}${REGISTER_API_PATH}/counties`,
    EDR_ONLINE_ADDRESS,
    EDRPRODUCT_PAYONCE: `${apiHost}${EDR_PRODUCT_API_PATH}/payonce`,
    ENVIRONMENT: environment,
    GRANT_TYPE,
    INTERNAL_HOST: internalHost,
    INVOICES_ADDRESS: `${apiHost}${INVOICE_API_PATH}/invoices`,
    LOGIN_ACCESS_TOKEN: `${apiHost}${AUTHENTICATION_TOKEN_PATH}`,
    LOGIN_GEODATA_ADDRESS: `${apiHost}${AUTHENTICATION_API_PATH}/geodatatoken`,
    LOGIN_REFRESH_ADDRESS: `${apiHost}${AUTHENTICATION_API_PATH}/login/refresh`,
    MUNICIPALITY_INFO_ADDRESS: `${apiHost}${REGISTER_API_PATH}/municipalities`,
    PING_ADDRESSES: {
      AUTHENTICATION: `${apiHost}${AUTHENTICATION_API_PATH}/ping`,
      PRODUCT_CATALOG: `${apiHost}${PRODUCT_CATALOG_API_PATH}/ping`,
      SHOPPING: `${apiHost}${SHOPPING_API_PATH}/ping`
    },
    POSTNAME_REGISTER: `${apiHost}${REGISTER_API_PATH}/postaladdresses`,
    PRICE_CALCULATOR_ADDRESS: `${apiHost}${PRICE_CALCULATOR_API_PATH}/prices`,
    PRODUCT_CATALOG_LOGISTICS_PRODUCTS_SUPPLIER_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/logistics/products`,
    PRODUCT_CATALOG_LOGISTICS_SUPPLIER_PRODUCT: `${apiHost}${PRODUCT_CATALOG_API_PATH}/logistics/products`,
    PRODUCT_CATALOG_PRODUCTS_CHANGED_DEADLINES_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/changeddeadlines`,
    PRODUCT_CATALOG_PRODUCTS_SERVICETORG_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/products/servicetorgs`,
    PRODUCT_CATALOG_PRODUCTS_SUPPLIER_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/products/suppliers`,
    PRODUCT_CATALOG_SUPER_QUASI_ATTRIBUTES_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/superquasiattributes`,
    PRODUCT_CATALOG_SUPER_QUASI_RELATIONS_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/superquasirelations`,
    PRODUCT_LIST_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/products`,
    PRODUCT_PARAMETERS_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/parameters`,
    PRODUCT_USERS_ADDRESS: `${apiHost}${PRODUCT_CATALOG_API_PATH}/users`,
    REALTY_ADDRESS: `${apiHost}${REALTY_API_PATH}`,
    RESTRICTED_CLIENT_ID: RESTRICTED_CLIENT_ID_INFOLAND,
    SALES_CLIENT,
    SHOPPING_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/orders`,
    SHOPPING_ASSETS_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/assets`,
    SHOPPING_BAG_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/shoppingbag`,
    SHOPPING_CUSTOMERS_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/customers`,
    SHOPPING_CUSTOMERS_V3: `${apiHost}${SHOPPING_API_PATH_V3}/customers`,
    SHOPPING_LOGISTICS_ORDERCOLLECTIONS: `${apiHost}${SHOPPING_API_PATH}/logistics/ordercollections`,
    SHOPPING_LOGISTICS_ORDERLINES: `${apiHost}${SHOPPING_API_PATH}/logistics/orderlines`,
    SHOPPING_LOGISTICS_ORDERS: `${apiHost}${SHOPPING_API_PATH}/logistics/orders`,
    SHOPPING_ORDER_MESSAGES_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/ordermessages`,
    SHOPPING_ORDER_MESSAGES_READ_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/ordermessages/read`,
    SHOPPING_ORDER_MESSAGES_SUPPLIER_ADDRESS: `${apiHost}${SUPPLIER_API_PATH}/ordermessages`,
    SHOPPING_ORDER_MESSAGES_SUPPLIER_READ_ADDRESS: `${apiHost}${SUPPLIER_API_PATH}/ordermessages/read`,
    SHOPPING_ORDERCOLLECTION_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/ordercollections`,
    SHOPPING_ORDERCOLLECTION_VALIDATE: `${apiHost}${SHOPPING_API_PATH}/ordercollections/validate`,
    SHOPPING_ORDERDATA_CADASTRE_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/orderdata/cadastre`,
    SHOPPING_ORDERDATA_SHARE_ADDRESS: `${apiHost}${SHOPPING_API_PATH}/orderdata/share`,
    SHOPPING_SUPPLIERASSETS_ADDRESS: `${apiHost}${SUPPLIER_API_PATH}/assets`,
    SHOPPING_SUPPLIERORDERS_ADDRESS: `${apiHost}${SUPPLIER_API_PATH}/supplierorders`,
    SHOPPING_SUPPLIERORDERS_USER_ADDRESS: `${apiHost}${SUPPLIER_API_PATH}/supplierorders/user`
  };
};

export const setEnvironment = (environment: string): ServicesConfig.Config => {
  config = generateConfig(environment);
  broadcast(ENVIRONMENT_SET);

  return config;
};

export const clearConfig = (): void => {
  config = null;
};

export const setClientId = (clientId: string): void => {
  config.CLIENT_ID = clientId;
};

export const getConfig = (): ServicesConfig.Config => {
  if (!config) {
    throw Error('Component environment is not set.');
  }

  return config;
};

export const isSet = (): boolean => {
  return !!config;
};

export const isProduction = (): boolean => {
  return getConfig().ENVIRONMENT === ENVIRONMENT_PROD;
};
