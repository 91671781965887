import React, { Component } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import styles from './App.module.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Download from './components/download/Download';
import Login from './components/login/Login';
import axios from 'axios';
import { dataServices } from 'ambita-components-core';

const { config } = dataServices;

let API_HOST;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCode: '',
      format: '',
      id: null,
      settlementNumber: '',
      isLoggedIn: false,
      loginFailed: false
    }
  }

  loginHandler = (settlementNumber, accessCode) => {
    if (settlementNumber && accessCode) {
      this.setState({
        settlementNumber: settlementNumber,
        accessCode: accessCode,
        loginFailed: false
      }, () => {
        this.fetchSettlement();
      });
    } else {  // Should throw
      this.setState({ loginFailed: true, isLoggedIn: false }, () => {
        this.fetchSettlement(); // fetch what settlement?
      });
    }
  };

  urlError = () => {
    this.setState({
      isLoggedIn: false,
      loginFailed: true
    })
  };

  downloadUnmount = () => {
    this.setState({ isLoggedIn: false, settlementNumber: '' });
  };

  fetchSettlement = () => {
    axios.get(`${API_HOST}/billing/v1/settlement/${this.state.settlementNumber}/specification`, {
      params: {
        accessCode: this.state.accessCode
      }
    }).then((response) => {
      this.setState({
        // id: response.data.id,
        isLoggedIn: true,
        loginFailed: false
      })
    }).catch((error) => {
      console.error('error: ', error);
      this.setState({
        isLoggedIn: false,
        loginFailed: true
      });
    });
  };

  componentDidMount() {
    API_HOST = config.isProduction() ? config.PROD_API_HOST : config.BETA_API_HOST;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const settlementNumber = urlSearchParams.get('settlementNumber')
    const accessCode = urlSearchParams.get('accessCode')
    if (settlementNumber && accessCode) {
      this.loginHandler(settlementNumber, accessCode)
    }
  }

  render() {
    return (
      <Router>
        <div className={styles.container}>
          <Header/>
          <div className={styles.content}>
            <Switch>
              {!this.state.isLoggedIn && (
                <Route exact path="/" render={(props) => (
                  <Login {...props}
                         settlementNumber={this.state.settlementNumber}
                         accessCode={this.state.accessCode}
                         loginHandler={this.loginHandler}
                         loginFailed={this.state.loginFailed}/>)}>
                </Route>)}
              {this.state.isLoggedIn && (
                <Route exact path={`/`} render={(props) => (
                  <Download {...props}
                            url={API_HOST}
                            urlError={this.urlError}
                            id={this.state.id}
                            settlementNumber={this.state.settlementNumber}
                            accessCode={this.state.accessCode}
                            isLoggedIn={this.state.isLoggedIn}
                            downloadUnmount={this.downloadUnmount}/>
                )}/>)}
            </Switch>
          </div>
          <Footer/>
        </div>
      </Router>
    );
  }
};
