import { getSettings } from '../services/settingsService';
import * as storageService from '../services/storageService';
import * as log from '../services/logService';
import * as tokenRefresh from './tokenRefresh';
import * as broadcastService from '../services/broadcastService';
import * as BroadcastConstants from '../constants/broadcast';
import Promise from '../promise';
import { Token } from '../typings/Token';
import { XHRError } from '../services/errorService';

interface TokenAccessor<T> {
  (): T;
  isRetrievedFromStorage?: boolean;
  token?: T;
}

const HTTP_STATUS_UNAUTHORISED = 401;
const TOKEN_EXPIRY_THRESHOLD_MIN = 2;
const TOKEN_EXPIRY_THRESHOLD_MS = 1000 * 60 * TOKEN_EXPIRY_THRESHOLD_MIN;
const AUTH_TOKEN_KEY = 'authtoken';
const GEO_TOKEN_KEY = 'geotoken';

let tokenRefreshPromise = null;
let geoTokenRefreshPromise = null;
const GEO_TOKEN_EXPIRATION_MIN = 60;

const tokenAccessor: TokenAccessor<Token.Token> = function(): Token.Token {
  if (tokenAccessor.token) {
    return tokenAccessor.token;
  }

  if (!tokenAccessor.isRetrievedFromStorage && getSettings().persistAccessTokenInStorage) {
    tokenAccessor.isRetrievedFromStorage = true;
    tokenAccessor.token = storageService.retrieve(AUTH_TOKEN_KEY, true);

    return tokenAccessor.token;
  }

  return null;
};

const geoTokenAccessor: TokenAccessor<Token.GeoToken> = function(): Token.GeoToken {
  if (geoTokenAccessor.token) {
    return geoTokenAccessor.token;
  }

  if (!geoTokenAccessor.isRetrievedFromStorage && getSettings().persistGeoTokenInStorage) {
    geoTokenAccessor.isRetrievedFromStorage = true;
    geoTokenAccessor.token = storageService.retrieve(GEO_TOKEN_KEY, true);

    return geoTokenAccessor.token;
  }

  return null;
};

const isGeoTokenExpired = function(geoToken: Token.GeoToken): boolean {
  if (!geoToken || !geoToken.expires) {
    return true;
  }

  const timeLeft = geoToken.expires.valueOf() - Date.now();

  return timeLeft < TOKEN_EXPIRY_THRESHOLD_MS;
};

/**
 * Returns currently used geotoken or gets a new one if there is no valid geotoken
 * @returns {Promise<GeoToken>} Returns Promise object that is resolved with GeoToken object
 */
export const getGeoToken = function(): Promise<Token.GeoToken> {
  const geoToken = geoTokenAccessor();

  if (geoToken && !isGeoTokenExpired(geoToken)) {
    return Promise.resolve(geoToken);
  } else if (geoTokenRefreshPromise) {
    return geoTokenRefreshPromise;
  }

  geoTokenRefreshPromise = tokenRefresh
    .geoTokenRefresh(GEO_TOKEN_EXPIRATION_MIN)
    .then((refreshedToken: Token.ApiGeoToken) => {
      const token = {
        expires: new Date(Date.now() + GEO_TOKEN_EXPIRATION_MIN * 60 * 1000),
        token: refreshedToken.token
      };

      geoTokenAccessor.token = token;

      if (getSettings().persistGeoTokenInStorage) {
        storageService.save(GEO_TOKEN_KEY, token);
      }

      geoTokenRefreshPromise = null;

      return token;
    })
    .catch(error => {
      geoTokenRefreshPromise = null;
      log.error('GeoToken refresh error', error);

      return Promise.reject(Error('GeoToken refresh error'));
    });

  return geoTokenRefreshPromise;
};

/**
 * Removes currently used GeoToken
 */
export const removeGeoToken = function(): void {
  geoTokenAccessor.token = null;
  storageService.remove(GEO_TOKEN_KEY);
};

/**
 * Set new authentication token using token data received from REST API.
 * Method calculates expiry date based on `expires_in` value.
 * @param {ApiToken} newToken Token data
 */
export const setToken = function(newToken: Token.ApiToken): void {
  const currentToken = tokenAccessor();

  if (!currentToken || newToken.access_token !== currentToken.access_token) {
    const updatedToken: Token.Token = { ...newToken };
    updatedToken.expires_at = new Date(Date.now() + newToken.expires_in * 1000);
    replace(updatedToken);
  }
};

/**
 * Replace token with an existing token with set expiry date
 * @param {Token} newToken Token that should replace current one
 */
const replace = function(newToken: Token.Token): void {
  tokenAccessor.token = newToken;

  if (getSettings().persistAccessTokenInStorage) {
    storageService.save(AUTH_TOKEN_KEY, newToken);
  }

  broadcastService.broadcast(BroadcastConstants.TOKEN_REPLACED);
};

/**
 * Removes current authentication token
 */
export const remove = function(): void {
  tokenAccessor.token = null;
  storageService.remove(AUTH_TOKEN_KEY);
  broadcastService.broadcast(BroadcastConstants.TOKEN_REMOVED);
};

/**
 * Returns whether there is a token and the token is valid
 * @returns {boolean} True if there is valid token
 */
export const isValidToken = function(): boolean {
  return tokenAccessor() !== null;
};

const tokenRefreshErrorHandling = function(error: XHRError): Promise<void> {
  tokenRefreshPromise = null;

  // if token expired broadcast appropriate message
  if (error && error.status === HTTP_STATUS_UNAUTHORISED) {
    broadcastService.broadcast(BroadcastConstants.TOKEN_EXPIRED_MESSAGE);
    remove();
  }

  return Promise.reject(Error('Token refresh failed'));
};

/**
 * Check if token is expired
 * @returns {boolean} True if there is no token or token is expired, False otherwise
 */
export const isExpired = function(): boolean {
  const token = tokenAccessor();

  if (!token || !token.expires_at) {
    return true;
  }

  const timeLeft = token.expires_at.valueOf() - Date.now();

  return timeLeft < TOKEN_EXPIRY_THRESHOLD_MS;
};

/**
 * Returns access token, null if no token is present or token refresh promise if token has expired
 * @returns {Promise | string} Access token or Promise that is resolved with access token
 */
export const getAccessToken = function(): Promise<string> | string {
  const currentToken = tokenAccessor();

  if (!currentToken || !currentToken.token_type || !currentToken.access_token) {
    return null;
  }

  if (isExpired()) {
    if (tokenRefreshPromise === null) {
      tokenRefreshPromise = tokenRefresh
        .tokenRefresh(currentToken.refresh_token)
        .then(tokenData => {
          setToken(tokenData);
          tokenRefreshPromise = null;

          return `${tokenData.token_type} ${tokenData.access_token}`;
        })
        .catch(tokenRefreshErrorHandling);
    }

    return tokenRefreshPromise;
  }

  return `${currentToken.token_type} ${currentToken.access_token}`;
};
