interface GAWindow extends Window {
  GoogleAnalyticsObject: string;
  ga(...args: any[]): void;
}

export const initializeAnalytics = (trackingId: string): void => {
  const GA_REFERENCE = 'ga';
  const date = new Date();
  const scriptTag = document.createElement('script');
  const firstScriptTag = document.getElementsByTagName('script').item(0);

  (window as GAWindow).GoogleAnalyticsObject = GA_REFERENCE;

  window[GA_REFERENCE] =
    window[GA_REFERENCE] ||
    function(...args: any[]): void {
      (window[GA_REFERENCE].q = window[GA_REFERENCE].q || []).push(args);
    };

  window[GA_REFERENCE].l = date.getTime();

  scriptTag.async = true;
  scriptTag.src = 'https://www.google-analytics.com/analytics.js';

  firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

  (window as GAWindow).ga('create', trackingId, 'auto');
  (window as GAWindow).ga('set', 'anonymizeIp', true);
};
