/* eslint-disable padding-line-between-statements */
// region services
import * as analytics from './services/analytics';
import * as broadcast from './services/broadcastService';
import * as browserDetection from './services/browserDetection';
import * as browserFeatureDetection from './services/browserFeatureDetection';
import * as dateFormatter from './services/dateFormatter';
import * as domUtils from './services/domUtils';
import * as error from './services/errorService';
import * as filters from './services/filters';
import * as i18n from './services/i18nService';
import * as log from './services/logService';
import * as storage from './services/storageService';
import * as urlCacher from './services/urlCacherService';
import * as util from './services/util';

export const services = {
  analytics,
  broadcast,
  browserDetection,
  browserFeatureDetection,
  dateFormatter,
  domUtils,
  error,
  filters,
  i18n,
  log,
  storage,
  urlCacher,
  util
};
// endregion

// region data services
import * as availabilityService from './dataServices/restServiceAvailability';
import * as codeRegisterService from './dataServices/codeRegister';
import * as logisticsService from './dataServices/Logistics';
import * as productCatalogService from './dataServices/productCatalog';
import * as realtyService from './dataServices/realty';
import * as servicesConfig from './dataServices/servicesConfig';
import * as shoppingService from './dataServices/shopping';
import * as supplierService from './dataServices/supplier';
import * as tokenRefreshService from './dataServices/tokenRefresh';
import * as tokenService from './dataServices/token';
import * as userService from './dataServices/user';
import * as xhrService from './dataServices/xhr';

export const dataServices = {
  availability: availabilityService,
  codeRegister: codeRegisterService,
  config: servicesConfig,
  logistics: logisticsService,
  productCatalog: productCatalogService,
  realty: realtyService,
  shopping: shoppingService,
  supplier: supplierService,
  token: tokenService,
  tokenRefresh: tokenRefreshService,
  user: userService,
  xhr: xhrService
};
// endregion

// region typings
export * from './typings/Asset';
export * from './typings/CodeRegister';
export * from './typings/Common';
export * from './typings/CoreSettings';
export * from './typings/GeoJSON';
export * from './typings/OrderCollection';
export * from './typings/OrderData';
export * from './typings/OrderMessage';
export * from './typings/Ping';
export * from './typings/ProductCatalog';
export * from './typings/Products';
export * from './typings/Realty';
export * from './typings/RequestConfig';
export * from './typings/RiotEvent';
export * from './typings/Search';
export * from './typings/ServicesConfig';
export * from './typings/Supplier';
export * from './typings/Token';
export * from './typings/User';
// endregion

// region constants
import * as broadcastConstants from './constants/broadcast';
import * as coreConstants from './constants/core';
import * as geoJSONConstants from './constants/geoJSON';
import * as keysConstants from './constants/keys';
import * as realtyConstants from './constants/realty';
import * as userConstants from './constants/user';

export const constants = {
  broadcast: broadcastConstants,
  core: coreConstants,
  geoJSON: geoJSONConstants,
  keys: keysConstants,
  realty: realtyConstants,
  user: userConstants
};
// endregion

import { EventEmitter } from 'eventemitter3';
import * as baseStore from './stores/baseStore';
import * as coreActions from './actions/coreActions';
import * as dispatcher from './dispatcher/dispatcher';
import coreStore from './stores/coreStore';
import polyfillPromise from './promisePolyfill';
import * as BluebirdPromise from 'bluebird';

export { baseStore, coreActions, coreStore, dispatcher, EventEmitter, polyfillPromise, BluebirdPromise as Promise };

// core setup
import { initTokenHandling } from './actions/coreInitActions';

initTokenHandling();
