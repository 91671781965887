import Promise from '../promise';
import { Store, StoreAction, StoreEvent } from './baseStore';
import { getSettings, setSettings } from '../services/settingsService';
import * as constants from '../constants/core';
import * as config from '../dataServices/servicesConfig';
import * as tokenService from '../dataServices/token';
import * as i18nService from '../services/i18nService';
import { CoreSettings } from '../typings/CoreSettings';
import { Token } from '../typings/Token';
import { User } from '../typings/User';
import { Logger } from '../typings/Logger';
import { clearOutdatedItems, clearOutdatedNamespaceItems } from '../services/storageService';
import { isNumber } from '../services/util';

export interface CoreState {
  logger: Logger;
  settings: CoreSettings;
  user: User.User;
}

export interface CoreAction extends StoreAction {
  language?: string;
  logger?: Logger;
  settings?: CoreSettings;
  tokenObject?: Token.Token;
  userData?: User.User;
}

const updateEnvironment = function(environment: string): void {
  if (!config.isSet() || config.getConfig().ENVIRONMENT !== environment) {
    config.setEnvironment(environment);
  }
};

const updateClientId = function(clientId: string): void {
  if (config.isSet() && clientId !== config.getConfig().CLIENT_ID) {
    config.setClientId(clientId);
  }
};

const applySettings = function(newSettings: CoreSettings): void {
  setSettings(newSettings);

  if (isNumber(newSettings.storageVersion)) {
    clearOutdatedItems(newSettings.storageVersion);
  }

  if (newSettings.storageNamespace && isNumber(newSettings.storageNamespaceVersion)) {
    clearOutdatedNamespaceItems(newSettings.storageNamespace, newSettings.storageNamespaceVersion);
  }

  if (newSettings.environment) {
    updateEnvironment(newSettings.environment);
  }

  if (newSettings.clientId) {
    updateClientId(newSettings.clientId);
  }

  if (newSettings.tokenObject) {
    updateToken(newSettings.tokenObject);
  }

  if (newSettings.language) {
    i18nService.setLanguage(newSettings.language);
  }
};

const updateSettings = function(state: CoreState, newSettings: CoreSettings): CoreState {
  applySettings(newSettings);

  return {
    ...state,
    settings: getSettings()
  };
};

/**
 * Sets new authentication token
 * @param {ApiToken} newToken
 */
const updateToken = function(newToken: Token.ApiToken): void {
  tokenService.setToken(newToken);
};

/**
 * Removes current authentication token
 */
const removeToken = function(): void {
  tokenService.remove();
  tokenService.removeGeoToken();
};

const logoutUser = function(state: CoreState): CoreState {
  return {
    ...state,
    user: null
  };
};

const setUser = function(state: CoreState, userData: User.User): CoreState {
  return {
    ...state,
    user: userData
  };
};

const setLogger = function(state: CoreState, logger: Logger): CoreState {
  return {
    ...state,
    logger
  };
};

export const eventCollector = function(_: CoreState, action: CoreAction): StoreEvent | StoreEvent[] {
  switch (action.actionType) {
    case constants.CHANGE_LANGUAGE_ACTION:
      return { name: constants.LANGUAGE_CHANGED_EVENT };
    case constants.CHANGE_SETTINGS_ACTION: {
      const changeSettingsEvents = [{ name: constants.SETTINGS_CHANGED_EVENT }];

      if (action.settings && action.settings.language) {
        changeSettingsEvents.push({ name: constants.LANGUAGE_CHANGED_EVENT });
      }

      return changeSettingsEvents;
    }

    case constants.AVAILABILITY_CHECKED_ACTION:
      return { name: constants.AVAILABILITY_CHECKED_EVENT };
    case constants.UPDATE_TOKEN_ACTION:
    case constants.REMOVE_TOKEN_ACTION:
    case constants.TOKEN_CHANGE_ACTION:
      return { name: constants.TOKEN_CHANGE_EVENT };
    case constants.LOGOUT_ACTION:
      return [{ name: constants.USER_CHANGE_EVENT }, { name: constants.LOGOUT_EVENT }];
    case constants.UPDATE_USER_ACTION:
      return { name: constants.USER_CHANGE_EVENT };
    case constants.LOGIN_SUCCESS_ACTION:
      return { name: constants.LOGIN_SUCCESS_EVENT };
    case constants.LOGIN_FAILED_ACTION:
      return { name: constants.LOGIN_FAILED_EVENT };
    case constants.TOKEN_NOT_FOUND_ACTION:
      return { name: constants.TOKEN_NOT_FOUND_EVENT };
    case constants.LOGIN_SERVICE_UNAVAILABLE_ACTION:
      return { name: constants.LOGIN_SERVICE_UNAVAILABLE_EVENT };
    default:
      return [];
  }
};

export const stateReducer = function(state: CoreState, action: CoreAction): CoreState {
  switch (action.actionType) {
    case constants.CHANGE_LANGUAGE_ACTION:
      return updateSettings(state, { language: action.language });

    case constants.CHANGE_SETTINGS_ACTION:
      return updateSettings(state, action.settings);

    case constants.UPDATE_TOKEN_ACTION:
      updateToken(action.tokenObject);

      return state;

    case constants.REMOVE_TOKEN_ACTION:
      removeToken();

      return state;

    case constants.LOGOUT_ACTION:
      return logoutUser(state);

    case constants.UPDATE_USER_ACTION:
      return setUser(state, action.userData);

    case constants.SET_LOGGER_ACTION:
      return setLogger(state, action.logger);

    default:
      return state;
  }
};

export class CoreStore extends Store<CoreState> {
  /**
   * @description Check if user can change own password
   * @returns {boolean} True if user can change own password
   */
  canChangePassword(): boolean {
    return this.isAuthenticated() && !this.isAnonymousCustomer() && this.getUser().canChangePassword;
  }

  getAccessToken(): Promise<string> | string {
    return tokenService.getAccessToken();
  }

  getGeoToken(): Promise<Token.GeoToken> {
    return tokenService.getGeoToken();
  }

  /**
   * @description Get logger
   * @returns {Logger} Logger
   */
  getLogger(): Logger {
    return this.getState().logger;
  }

  /**
   * @description Get user logon ident
   * @returns {string} Logon ident of current user
   */
  getLogonIdent(): string {
    const user = this.getUser();

    return user ? user.logonIdent : '';
  }

  /**
   * Returns current application settings
   * @returns {CoreSettings} Settings object
   */
  getSettings(): CoreSettings {
    return this.getState().settings;
  }

  /**
   * @description Get user data for currently logged in user
   * @returns {User} Currently logged in UserData
   */
  getUser(): User.User {
    return this.getState().user;
  }

  /**
   * @description Get user email
   * @returns {string} Email of current user
   */
  getUserEmail(): string {
    const user = this.getUser();

    return user ? user.email : '';
  }

  /**
   * @description Get user name
   * @returns {string} Name of current user
   */
  getUserName(): string {
    const user = this.getUser();

    return user ? user.name : '';
  }

  /**
   * @description Get user organization code
   * @returns {string} Organization code of current user
   */
  getUserOrganizationCode(): string {
    const user = this.getUser();
    const code = user && user.organization && user.organization.code;

    return code || '';
  }

  /**
   * @description Check if user has access only to Norges Eiendommer
   * @returns {boolean} True if user has access only to Norges Eiendommer
   */
  hasAccessOnlyToNorgesEiendommer(): boolean {
    return !this.isUserAbleToShop() && this.hasAccessToNorgesEiendommer();
  }

  /**
   * @description Check if user has access to Norges Eiendommer Basis
   * @returns {boolean} True if user has access to Norges Eiendommer Basis
   */
  hasAccessToNewBasis(): boolean {
    return this.isAuthenticated() && this.getUser().isNewBasis;
  }

  /**
   * @description Check if user has access to Norges Eiendommer Eksport
   * @returns {boolean} True if user has access to Norges Eiendommer Eksport
   */
  hasAccessToNewEksport(): boolean {
    return this.isAuthenticated() && this.getUser().isNewEksport;
  }

  /**
   * @description Check if user has access to Norges Eiendommer
   * @returns {boolean} True if user has access to Norges Eiendommer
   */
  hasAccessToNorgesEiendommer(): boolean {
    return this.hasAccessToNewBasis() || this.hasAccessToNewEksport();
  }

  /**
   * @description Check if user has any admin rights
   * @returns {boolean} True if user has any admin rights
   */
  hasAdminRights(): boolean {
    return this.isUserAdmin() || this.isCustomerAdmin() || this.isSuperAdmin();
  }

  /**
   * @description Check if user is an anonymous customer
   * @returns {boolean} True if user is an anonymous customer
   */
  isAnonymousCustomer(): boolean {
    return this.isAuthenticated() && this.getUser().isAnonymousCustomer;
  }

  /**
   * @description Check if user is authenticated
   * @returns {boolean} True if user is authenticated
   */
  isAuthenticated(): boolean {
    return !!this.getUser();
  }

  /**
   * @description Check if user is authenticated and is not an anonymous customer
   * @returns {boolean} True if user is authenticated and is not an anonymous customer
   */
  isAuthenticatedNotAnonymousCustomer(): boolean {
    return this.isAuthenticated() && !this.getUser().isAnonymousCustomer;
  }

  /**
   * @description Check if user is a credit card customer
   * @returns {boolean} True if user is a credit card customer
   */
  isCreditCardCustomer(): boolean {
    return this.isAuthenticated() && this.getUser().isCreditCardCustomer;
  }

  /**
   * @description Check if user is a customer admin
   * @returns {boolean} True if user is a customer admin
   */
  isCustomerAdmin(): boolean {
    return this.isAuthenticated() && this.getUser().isCustomerAdmin;
  }

  /**
   * @description Check if user is new Infoland exclusive
   * @returns {boolean} True if user is new Infoland exclusive
   */
  isNewInfolandExclusive(): boolean {
    return this.isAuthenticated() && this.getUser().isNewInfolandExclusive;
  }

  /**
   * @description Check if components is in serviceTorg-mode
   * @returns {boolean} True if serviceTorg is set
   */
  isServiceTorg(): boolean {
    return !!this.getState().settings.serviceTorg;
  }

  /**
   * @description Check if user is authenticated and is not an anonymous customer and is not a supplier
   * @returns {boolean} True if user is authenticated and is not an anonymous customer and is not a supplier
   */
  isStandardCustomer(): boolean {
    return this.isAuthenticated() && !this.isAnonymousCustomer() && !this.isSupplier();
  }

  /**
   * @description Check if user is a subscription customer
   * @returns {boolean} True if user is a subscription customer
   */
  isSubscriptionCustomer(): boolean {
    return this.isAuthenticated() && this.getUser().isSubscriptionCustomer;
  }

  /**
   * @description Check if user is a subscription customer or a supplier
   * @returns {boolean} True if user is a subscription customer or a supplier
   */
  isSubscriptionCustomerOrSupplier(): boolean {
    return this.isSubscriptionCustomer() || this.isSupplier();
  }

  /**
   * @description Check if user is a super admin
   * @returns {boolean} True if user is a super admin
   */
  isSuperAdmin(): boolean {
    return this.isAuthenticated() && this.getUser().isSuperAdmin;
  }

  /**
   * @description Check if user is a supplier
   * @returns {boolean} True if user is a supplier
   */
  isSupplier(): boolean {
    return this.isAuthenticated() && this.getUser().isSupplier;
  }

  /**
   * @description Check if user is able to shop
   * @returns {boolean} True if user is able to shop
   */
  isUserAbleToShop(): boolean {
    return this.isAuthenticated() && this.getUser().isUserAbleToShop;
  }

  /**
   * @description Check if user is an admin
   * @returns {boolean} True if user is an admin
   */
  isUserAdmin(): boolean {
    return this.isAuthenticated() && this.getUser().isUserAdmin;
  }

  /**
   * @description Check if username is authenticated
   * @returns {boolean} True if username is authenticated
   */
  isUsernameAuthenticated(username: string): boolean {
    if (!this.isAuthenticated() || !username) {
      return false;
    }

    return this.getLogonIdent().toLowerCase() === username.toLowerCase();
  }

  /**
   * @description Check if username is authenticated as a credit card customer
   * @returns {boolean} True if username is authenticated as a credit card customer
   */
  isUsernameAuthenticatedAsCreditCardCustomer(username: string): boolean {
    if (!username) {
      return false;
    }

    return this.isUsernameAuthenticated(username) && this.isCreditCardCustomer();
  }

  /**
   * @description Check if username is authenticated as a subscription customer or a supplier
   * @returns {boolean} True if username is authenticated as a subscription customer or a supplier
   */
  isUsernameAuthenticatedAsSubscriptionCustomerOrSupplier(username: string): boolean {
    if (!username) {
      return false;
    }

    return this.isUsernameAuthenticated(username) && (this.isSubscriptionCustomer() || this.isSupplier());
  }

  /**
   * Returns whether there is a token and the token is valid
   * @returns {boolean}
   */
  isValidToken(): boolean {
    return tokenService.isValidToken();
  }
}

const createInitialStoreState = function(): CoreState {
  return {
    logger: null,
    settings: getSettings(),
    user: null
  };
};

const coreStoreInstance = new CoreStore(stateReducer, eventCollector, createInitialStoreState());
export default coreStoreInstance;
