import React, { Component } from 'react';
import styles from './Login.module.scss';
import { Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settlementNumber: props.settlementNumber,
      accessCode: props.accessCode
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.loginHandler(e.target.settlementNumber.value, e.target.accessCode.value);
    this.props.history.push(`/?settlementNumber=${this.state.settlementNumber}&accessCode=${this.state.accessCode}`);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.loginForm}>
            <h3>Hent oppgjørsspesifikasjon</h3>
            <br/>
            <Form onSubmit={this.handleSubmit} onChange={this.handleChange} ref={form => this.form = form}>
              <Form.Group name={'settlementNumber'}>
                <Form.Label>Oppgjørsnummer</Form.Label>
                <Form.Control state={this.state.settlementNumber} name={'settlementNumber'} type="text"/>
              </Form.Group>
              <Form.Group name={'accessCode'}>
                <Form.Label>Aksesskode</Form.Label>
                <Form.Control name={'accessCode'} type="text"/>
              </Form.Group>
              <Form.Text className={`${'text-muted'} ${styles.loginFormInfotext} `}>
                Bruk aksesskoden angitt på oppgjørsskjemaet for å logge inn for å laste ned detaljer om oppgjørsskjema.
              </Form.Text>
              <div className={styles.loginFormSubmitSection}>
                <Button size={'md'} className={`${styles.loginFormButton}`}
                        variant="primary"
                        type="submit">
                  Logg inn
                </Button>
                {this.props.loginFailed &&
                <span className={`${styles.loginFormErrortext} `}>
                  Innloggingsinformasjonen feilet, vennligst prøv igjen.
                </span>}
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
};

export default withRouter(Login);
